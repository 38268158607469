@media (max-width: 1300px) {
    .products-container {
        a {
            font-size: 1.1rem;
            
            h2 {
                min-height: 55px;
            }
            
            h3 {
                min-height: 50px;
            }
        }
    }
    
     // Camera Page
}