@media (max-width: 795px) {
    .paralax-container {
        display: none;
    }
    
    .title-wraper {
        font-size: .9rem;
    }
    
    .nav-container {
        display: none;
    }
    
    .nav-container-res {
        display: block;
    }
    
    .nav-menu {
        margin-left: auto;
    }
    
    .logo-tesa-main-res {
        display: block;
        margin-top: 1em;
        margin-left: 2em;
        
        img {
            max-width: 55px;
            height: auto;
            position: relative;
            z-index: 100;
        }
    }
    
    .res-menu-items-logo {    
        padding: 2rem 0;
        display: flex;
        gap: 1em;
        align-items: center;
        font-size: 1.5em;
        
        h3 {
            text-transform: uppercase;
            font-size: inherit;
            margin: 0;
            margin-top: 9px;
        }
        
        img {
            max-width: 2.7em;
            height: auto;
        }
    }
    
    .res-menu-items-second {
        font-size: 1.4rem;
        padding: 1rem;
    }
    
    .footer-container-res {
        max-width: 100%;
    }
    
    .products-container {
        
        a { 
            flex-basis: 100%;
            
            img {
                margin-bottom: 10px;
            }
        }
    }

    
     // Camera Page
     
    .camera-container {
        background-position: -140px;
    }

    // Telephones Page

    .advanced-text-wraper {
    flex-wrap: nowrap;
    flex-direction: column;
    font-size: 1em;
    }
    
    .telephones-wraper {
    display: block;
    
        div {
            flex-basis: auto;
        }
        h3 {
            flex-basis: auto;
        }
    }
    
    
    // Kontakt Page 
    
    .info-wraper {
        div {
            flex-basis: 100%;
        }
    }
}
