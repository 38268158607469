.contact-container {
    @include container;
    padding: 2.5em 2em;
    
    display: flex;
    justify-content: space-between;
}

.info-wraper {
    flex: 1 1 auto;
    margin-left: 5em;
    color: white;
    
    h1 {
        font-size: 2.5em;
    }
    
    h2, h3 {
        margin-bottom: .3em;
    }
    
    p {
        margin: 0;
    }
}

.camera-order > div:nth-child(1) > h2:nth-child(1) {
    color: black;
}

.contact-form-bgr {
    padding-top: 100px;
    background-color: $master-color;
    
    box-shadow: 0 0 20px rgba(#000, .4);
}

.map-wraper {
    flex: 0 1 auto;
    border-radius: .5rem;
}

.vue-map {
    border-radius: .5em;
    box-shadow: 0 0 8px rgba(#000000, .2);
}