.ezs-container {
    background-image: url(/img/zabezpecovaci-system.webp);
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    position: absolute;
    width: 100%;
    height: 700px;
    z-index: -1;
    top: 0;
    left: 0;
}

.ezs-img {
    border-radius: .4em;
    box-shadow: 0 0 10px rgba(#000000, .2);
}

.ezs-img-view {
    max-height: 210px !important;
}