.telephones-container {
    background-image: url(/img/videovratnik-full.webp);
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    position: absolute;
    width: 100%;
    height: 700px;
    z-index: -1;
    top: 0;
    left: 0;
}

.telephones-wraper {
    flex-basis: 33.333%;
    padding: 1.5em 1.5em;
    background-color: lighten($master-color-box, 15%);
    cursor: pointer;
    
    border: 1px solid darken($master-color, 20%);
    border-radius: .4rem;
    
    box-shadow: 0px 0px 3px darken($master-color, 20%);
    
    transition: all .2s ease-in-out;
    
    h3 {
        font-weight: 400;
    }
    
    img {
        max-height: 250px;
        width: auto;
    }
}

.telephones-wraper:hover {
    box-shadow: 0px 0px 8px darken($master-color, 30%);
    border: 1px solid darken($master-color, 30%);
    background-color: lighten($master-color-box, 5%);
}

.advanced-wraper {
    color: black;
    
    h3 {
        color: darken($master-color-noalpha, 10%);
        font-size: 1.5em;
        margin-bottom: .2em;
    }
    
    h4 {
        color: $master-color-noalpha;
        font-size: 1.3em;
        margin-bottom: 0;
    }
    
    p {
        margin-top: .5em;
        font-size: 1.1em;
    }
}

.advanced-text-wraper {
    display: flex;
    flex-direction: column;
}


.advanced-sub-wraper {
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
    
    text-align: left;
    
    div {
        flex: 0 1 30%;
    }
    
    img {
        max-height: 400px;
        width: auto;
        margin: 1.5em 0 0 0;
    }
    
}

.advanced-last-sub {
    
    p {
        max-width: 85%;
        margin: 0 auto;
    }
}

.telephone-form-wraper {
    width: 100%;
    background-color: rgba(#fff, 0.9);
}

