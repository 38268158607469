.smarthome-container {
    background-image: url(/img/smarthome-carousel.webp);
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    position: absolute;
    width: 100%;
    height: 700px;
    z-index: -1;
    top: 0;
    left: 0;
}

.kurviza {
    font-style: italic;
}

.smarthome-subtitle-bgr {
    width: 100%;
    background-color: $master-color-noalpha;
    color: white;
    
    position: relative;
    z-index: 1;
    box-shadow: 0 0 20px rgba(#000, .4);
}