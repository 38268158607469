@media (max-width: 960px) {
    .nav-container {
        font-size: .9rem;
    }
    
    .logo-tesa-main::before {
        transform: scale(.8);
        top: -14px;
    }
    
    .title-wraper {
        font-size: 1rem;
    }
    
    .services-container {
        article {
            flex-basis: 100%;
        }
    }
    
    .products-container {
        
        a { 
            h3 {
                min-height: 45px;
            }
        }
    }
    
    .full-container {
        background-image: url(/img/zabezpecenie-objektov-thirt.webp);
        background-position: 0;
    }
    
     // Camera Page
     
    .camera-text {
        font-size: .9em;
    }
     
    .camera-component {
        flex-wrap: nowrap;
        flex-direction: column;
        font-size: 1em;
     }
     
     .components-wraper {
        display: block;
        
        div {
            flex-basis: auto;
        }
        h3 {
            flex-basis: auto;
        }
        
     }
     
     // Product Info Box
     
     .advanced-sub-wraper {
        
        img {
            max-height: 300px;
        }
        
    }
    
    // FAQ
    
    .faq-container {
        display: block;
        justify-content: unset;
        align-items: unset;
        gap: unset;
    }
    
    .faq-title-wraper {
        margin-bottom: 1.5em;
    }
     
}