
// Fonts
@import url(https://fonts.bunny.net/css?family=cambay:400,700);

// Variables
@import 'variables';

// Mixins
@import 'mixin';

// Normalize
@import '../../css/normalize.css';

// Cookies
@import './cookies';

// Pages
@import 'cameraPage';
@import 'conditionsPage';
@import 'faqPage';
@import 'contactPage';
@import 'referencesPage';
@import 'smarthomePage';
@import 'telephonesPage';
@import 'ezsPage';
@import 'referencesPage';
@import 'brandsPage';


body {
    font-family: 'Cambay', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
}

.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
}

.full-container {
    background-image: url(/img/zabezpecenie-objektov.webp);
    background-size: cover;
    position: absolute;
    background-position:center;
    width: 100%;
    height: 700px;
    z-index: -2;
    top: 0;
    left: 0;
}

.background-container {
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    width: 100%;
    height: 700px;
    z-index: -1;
    top: 0;
    left: 0;
}

.title-container {
    width: 100%;
    
    display: flex;
    flex-direction: column;
    min-height: 700px; 
    
    position: relative;
    z-index: 1;
    box-shadow: 0 0 20px rgba(#000, .4);
}


.flex-start {
    justify-content: flex-start;
    padding-left: 3em
}

.logo-tesa-main::before {
    background-image: url('/img/bielykruhslogom.webp');
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    content: '';
}

.logo-tesa-main {
    display: flex;
    align-items:flex-end;
    transform: scale(1);
    transition: transform .2s ease-in-out;
    font-weight: 700;
}

.logo-tesa-main:hover {
    transform: scale(1.08)
}

.nav-container {
    @include container;
    
    padding: 1em 2em;
    position: relative;
    z-index: 101;
    
    display: flex;
    justify-content: space-between;
    
    color: $second-color;
    
    div {
        font-size: 1.6em;
        text-transform: uppercase;
    }
}

.nav-container-res {
    display: none;
}

.navigation {
    ul {
        display: flex;
        padding-left: 0;
    }
}

.title-wraper-top {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
    position: relative;
    z-index: -1;
    height: 7rem;
    margin-bottom: auto;
}

.title-wraper {
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
    position: relative;
    z-index: -1;
    padding-bottom: 3rem;
    margin-top: auto;
}

.master-title {
    max-width: 70%;
    color: white;
    margin-left: 15%;
    text-shadow: 0px 0px 3px black;
    
    h1 {
        font-size: 3em;
    }
}

.sub-title {
    max-width: 70%;
    color: white;
    text-shadow: 0px 0px 3px black;
    margin-left: 18%;
    
    h2 {
        font-weight: 400;
    }
}

.paralax-container {
    background: url(/img/bg_satel_2.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    height: 99vh;
    z-index: -5;
    top: 700px; 
}

.space-creator-services {
    width: 100%;
    background-color: rgba($second-color, .85);
    z-index: -1;  
}

.services-container {
    @include container;
    
    padding: 3.5em 2em;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
    
    font-size: 1.1em;
    
    article {
        flex-basis: 48%;
    }
}

.space-creator-prod {
    width: 100%;
    background: $gradient-end;
    background: linear-gradient(
        90deg,
        $gradient-start-a 0%,
        $gradient-end-a 82%
    );
    color: $second-color;
    z-index: -1;
    box-shadow: 0 0 20px rgba(#000, 0.4)
}

.products-container {
    @include container;
    
    padding: 2.5em 2em;
    
    text-align: center;
    font-size: 1.2em;
    
    display: flex;
    justify-content: space-between;
    gap: 1em;
    
    h2 {
        min-height: 86.4px;
    }
    h3 {
        min-height: 67.4px;
    }
    
    img {
        max-height: 200px;
        width: auto;
    }
    
    a {
        cursor: pointer;
        flex-basis: 25%;
        padding: 1.5em 1.5em;
        background-color: lighten($master-color-box, 15%);
        
        border: 1px solid darken($master-color, 20%);
        border-radius: .4rem;
        
        box-shadow: 0px 0px 3px darken($master-color, 20%);
        
        transition: all .2s ease-in-out;
    }
    
    a:hover {
        box-shadow: 0px 0px 8px darken($master-color, 30%);
        border: 1px solid darken($master-color, 30%);
        background-color: lighten($master-color-box, 5%);
    }
}

.btn-1 {
    display: inline-block;
    border-radius: .3em;
    
    font-size: 1.2em;
    
    background-color: white;
    color: rgba(35,124,235,1);
    
    margin-top: 1.5em;
    padding: .6em .6em .4em .6em;
}

.space-creator-footer {
    width: 100%;
    background-color: darken($master-color, 20%);
    color: lighten($master-color, 40%);
    z-index: -1;  
    box-shadow: 0 0 20px rgba(#000, .4);
    
    h4 {
        color: white;
        text-transform: uppercase
    }
}

.footer-container {
    @include container;

    padding: 1.5em 5em;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    font-size: 1.1em;
    line-height: 1.7;
    
    p {
        margin: 0px;
    }
    
    div {
        flex: 0 1 auto;
        
        ul {
            padding: 0;
            
            li {
                list-style: none;
                display: flex;
                
                i {
                    line-height: 1em;
                }
            }
        }
        
        h4 {
            margin-bottom: 1rem;
        }   
    }
    
    div:nth-child(2) {
        margin: 0 auto;
    }
}


.footer-container-res {
    display: none;
}

.size-ico-1 {
    width: 37px;
    height: auto;
    vertical-align: middle;
}

.footer-temp {
    margin-top: 20px;
}

.hide-offer {
    display: none
}

// Media Queries

@import './mediaQueries/min1800';
@import './mediaQueries/max1300';
@import './mediaQueries/max1200';
@import './mediaQueries/max960';
@import './mediaQueries/max890';
@import './mediaQueries/max795';
@import './mediaQueries/max560';

