.conditions-wraper {
    @include container;
    
    margin-top: 85px;
    padding: 2.5em 2em;
    min-height: 75vh;
}

.conditions-ol {
    font-size: 1.3rem;
}

.conditions-ol-li {
    margin-bottom: .5rem;
}

