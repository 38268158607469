.references-bgr {
    padding-top: 100px;
    background-color: $master-color;
}

.references-container {
    @include container;
    padding: 2.5em 2em;
    
    h1 {
        text-align: center;
        color: white;
        font-size: 2.5em;
    }
}

.client-wraper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
    
}

.clients {
    flex: 0 1 24%;
    
    padding: 1.5em 1.5em;
    
    background-color: lighten($master-color-box, 15%);
    border: 1px solid darken($master-color, 20%);
    border-radius: .4rem;
    
    box-shadow: 0px 0px 3px darken($master-color, 20%);
    transition: all .2s ease-in-out;
    
    h2 {
        color: white;
        text-align: center;
    }
    
    img {
        max-width: 280.233px;
        height: auto;
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, .5));
    }
}

.clients:hover {
    box-shadow: 0px 0px 8px darken($master-color, 30%);
    border: 1px solid darken($master-color, 30%);
    background-color: lighten($master-color-box, 5%);
}