// Color

$master-color: rgba(78, 125, 166, .9);
$master-color-noalpha: rgba(78, 125, 166, 1);
$master-color-box: rgba(78, 125, 166, .5);
$second-color: #fff; 

$gradient-start: rgba(25,151,229,1);
$gradient-end: rgba(35,124,235,1);

$gradient-start-a: rgba(78, 125, 166, .9);
$gradient-end-a: rgba(94, 131, 163, 0.9);
