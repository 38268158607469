.faq-container {
    @include container;
    padding: 2.5em 2em;
    margin-top: 95.2px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.faq-title-wraper {
    flex: 1 1 auto;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    
    cursor: pointer;
    
    h1 {
        font-size: 2.5em;
        
        text-shadow: 0 15px 30px rgba(#000000, .4);
        transition: text-shadow 300ms ease-in-out;
        &:hover {
            text-shadow: 0 10px 25px rgba(#000000, .6);
        }
    }
}

.faq-answer-wraper {
    flex: 1 1 50%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    
    h2 {
        font-size: 1em;
        margin: 0;
    }
    
    h3 {
        font-size: 1em;
        margin: 0;
    }
}