// Cookies 

[class^="lcc-"] {
    box-sizing: border-box !important;
    font-size: 16px;
    line-height: 22px;
    color: #111;
  }
  
  [class^="lcc-"]:first-child {
    margin-top: 0;
  }
  
  [class^="lcc-"]:last-child {
    margin-bottom: 0;
  }
  
  [class^="lcc-"][inert] {
    pointer-events: none;
    cursor: default;
  }
  
  [class^="lcc-"][inert],
  [class^="lcc-"][inert] * {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  
  /* Text */
  
  .lcc-text {
    font-size: 16px;
    margin: 0 0 22px;
  }
  
  /* Button */
  
  .lcc-button {
  
    display: inline-block;
    background: none;
    background-color: #111;
    color: white;
    border: 1px solid #111;
    outline: none;
    font-size: inherit;
    padding: 6px 15px;
    cursor: pointer;
    margin: 1px 0;
    transition:
      color 0.2s ease,
      background-color 0.2s ease,
      border-color 0.2s ease;
  
    &:hover,
    &:focus {
      background: #777;
      border-color: #777;
    }
  }
  
  .lcc-button.lcc-button--link {
  
    color: inherit;
    background: transparent;
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
    text-decoration: underline;
  
    &:hover,
    &:focus {
      background: transparent;
      border-color: transparent;
      text-decoration: none;
    }
  
    & + & {
      margin-top: 0;
    }
  }
  
  /* Label + Input */
  
  .lcc-label {
  
    margin-bottom: 5px;
    display: flex;
    align-items: baseline;
  
    &[for] {
      cursor: pointer;
    }
  
    > * {
      margin-right: 10px;
    }
  }
  
  input[id^="lcc-"]:disabled {
    
    cursor: default;
    color: #777;
    opacity: .55;
  
    & + span {
      cursor: default;
      opacity: .6;
    }
  }
  
  /* Backdrop */
  
//   .lcc-backdrop {
//     position: fixed;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     background: rgba(0, 0, 0, 0.6);
//     z-index: 10000;
//     transition: opacity 0.2s ease-in-out;
//   }
  
  /* Modal */
  
  .lcc-modal {
  
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    overflow: auto;
    background-color: rgba(white, .8);
    backdrop-filter: blur(5px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    z-index: 10001;
    padding: 30px;
  
    .lcc-modal__close {
  
      width: 30px;
      font-size: 24px;
      line-height: 1.25;
      position: absolute;
      right: 0;
      top: 0;
      background: none;
      color: inherit;
      border: none;
      cursor: pointer;
      transition: color 0.2s ease;
  
      &:hover,
      &:focus {
        color: #777;
      }
    }
  
    .lcc-modal__title {
      font-size: 22px;
      margin-bottom: 1em;
    }
  
    .lcc-modal__section {
      margin-bottom: 20px;
    }
  
    .lcc-modal__actions {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 2em;
      
      button {
        margin: 0;
      }
    }
  
    .lcc-modal__actions > * {
      display: block;
      border-radius: .3em;
    }
  }
  
  .lcc-modal.lcc-modal--settings {
    z-index: 10002;
  }
  
  /* Utility */
  
  .lcc-u-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  
  .lcc-u-text-center {
    text-align: center !important;
  }