@media (max-width: 890px) {
    .title-wraper {
        font-size: .9rem;
        padding: 0 2.5em;
    }
    
    .master-title {
        max-width: 100%;
        margin-left: 0;
    }
    
    .sub-title {
        max-width: 100%;
        margin-left: 0;
        padding-bottom: 3em;
    }
    
    .footer-container {
        display: none
    }
    
    .footer-container-res {
        @include container;
        max-width: 80%;
    
        padding: 1.5em 2.5em;
        
        display: flex;
        flex-direction: column;
        
        font-size: 1.2rem;
        line-height: 2;
        
        div {
            h4 {
                margin-bottom: 0;
                cursor: pointer;
            }
        }
    }
    
    .footer-list {
        padding: 0 0 0 1rem;
            
        li {
            list-style: none;
            display: flex;
            
            i {
                line-height: 1em;
            }
            p {
                margin: 0;
            }
        }
    }
    
    .specific-footer-item {
        h4 {
            display: none;
        }
        
        p {
            margin: 0;
        }
    }
    
     // Camera Page
     
     .camera-container {
        background-position: -100px;
     }
     
     // Product info Box 
     
     
     .advanced-sub-wraper {
        font-size: 1em;
        flex-direction: column;
        
        img {
            max-height: 100%;
            height: auto;
            margin: 0 0 0 0;
        }
     }
     
     // FAQ
     
     .box > div > h2 {
        font-size: .8em;
    }
    
    .box > div > h3 {
        font-size: .7em;
    }
    
    .faq-title-wraper {
        font-size: .9em;
    }

}