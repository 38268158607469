.camera-container {
    background-image: url(/img/mobilna-aplikacia-kamery.webp);
    background-repeat: no-repeat;
    background-size:cover;
    position: absolute;
    background-position:center;
    width: 100%;
    height: 700px;
    z-index: -1;
    top: 0;
    left: 0;
}

.camera-text {
    @include container;
    padding: 2.5em 2em;
    
    h3 {
        font-weight: 400;
    }
}

.bgr-for-paralax {
    background: rgba(#fff, 0.9);
}


.camera-component {
    @include container;
    display: flex;
    gap: 1em;
    padding: 2.5em 2em;
    justify-content: space-between;
    text-align: center;
}

.components-wraper {
    flex-basis: 33.333%;
    padding: 1.5em 1.5em;
    background-color: lighten($master-color-box, 15%);
    
    border: 1px solid darken($master-color, 20%);
    border-radius: .4rem;
    
    box-shadow: 0px 0px 3px darken($master-color, 20%);
    
    transition: all .2s ease-in-out;
    
    h3 {
        font-weight: 400;
    }
    
    img {
        max-height: 200px;
        width: auto;
    }
}

.components-wraper:hover {
    box-shadow: 0px 0px 8px darken($master-color, 30%);
    border: 1px solid darken($master-color, 30%);
    background-color: lighten($master-color-box, 5%);
}


.camera-background {
    background: $gradient-end;
    background: linear-gradient(
        90deg,
        $gradient-start-a 0%,
        $gradient-end-a 82%
    );
    color: $second-color;
    width: 100%;
    
    box-shadow: 0 0 20px rgba(#000, .4);
}

.camera-order {
    @include container;
    padding: 2.5em 2em;
    
    p {
        font-size: 1.2em;
    }
}

.pb-0 {
    padding-bottom: 0;
}

.camera-text-wraper {
    width: 100%;
    background-color: rgba(#fff, 0.9);
}