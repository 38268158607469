.brands-aside {
    width: 100%;
    background-color: rgba(#fff, .9);
    position: relative;
    z-index: -1;
    
    h3 {
        text-align: center;
        margin: 0;
        padding: 1.5em 2.5em;
        font-size: 1.8em;
    }
}

.brands-img-wraper {
    position: relative;
    width: 100%;
    height: 16em;
    overflow: hidden;
    padding: 0 2.5em 2em 2.5em;
    
    display: flex;
    gap: 4em;
}

@media(max-width: 795px) {
    .brands-aside {
        h3 {
            font-size: 1.4em;
            padding: 1em 1.5em 0 1.5em;
        }
    }
    
    .brands-img-wraper {
        height: 12em;
    }
}