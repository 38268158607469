@media (max-width: 1200px) {
    .nav-container {
        font-size: 1rem;
    }
    
    .logo-tesa-main {
        position: relative;
        top: 14px;
        left: 80px;
    }
    
    .logo-tesa-main::before {
        position: absolute;
        top: -13px;
        left: -60px;
    }
    
    .title-wraper {
        font-size: 1rem;
    }
    
    .products-container {
        flex-wrap: wrap;
        gap: 2rem;
        
        a {
            flex-basis: 48%;
        }
    }
    
    .full-container {
        background-position: -120px;
    }
    
     // Camera Page
     
     .camera-component {
        flex-wrap: wrap;
     }
     
     .components-wraper {
        flex-basis: 100%;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2em;
        
        div {
            flex-basis: 30%;
        }
        h3 {
            flex-basis: 50%;
        }
        
     }
     
     // Telephones Page
     
     .telephones-wraper {
        flex-basis: 100%;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2em;
        
        div {
            flex-basis: 30%;
        }
        h3 {
            flex-basis: 65%;
        }
     }
     
     .advanced-last-sub {
        p {
            max-width: 100%;
            text-align: left;
        }
     }
     
     
    // Product Info Box

    .advanced-sub-wraper {
        justify-content: space-evenly;
        
        img {
            max-height: 350px;
            margin: 3em 0 0 0;
        }
    }
    
    // Kontakt Page
    
    .contact-container {
        display: block;
        justify-content: unset;
    }
    
    .info-wraper {
        flex: unset;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 2em;
        margin: 0;
        
        h1 {
            flex-basis: 100%;
        }
        
        div {
            align-self: flex-start;
        }
        
        
    }
    
    
    // FAQ
    
    .box > div > h2 {
        font-size: .9em;
    }
    
    .box > div > h3 {
        font-size: .8em;
    }
    
    .box {
        min-height: 130px !important;
    }
}