@media (max-width: 560px) {
    .title-wraper {
        font-size: .8rem;
    }
    
    .title-wraper {
        padding: 0 1em;
    }
    
    .services-container {
        padding: 1.5em 1em;
    }
    
    .logo-tesa-main-res {
        margin-top: 1em;
        margin-left: 1em;
        
        img {
            max-width: 40px;
            height: auto;
        }
    }
    
    .res-menu-items-logo {    
        font-size: 1.2em;
        
        img {
            max-width: 2.2em;
            height: auto;
        }
    }
    
    .res-menu-items-second {
        font-size: 1.1rem;
        padding: .5rem;
    }
    
    .products-container {
        padding: 1.5em 1em;
        gap: 1em;
        
        a {
            h2 {
                min-height: 45px;
            }
            
            h3 {
                min-height: 35px;
            }
        }
    }
    
    .footer-container-res {
        padding: 1.5em 1em;
    }
    
    .full-container {
        background-image: url(/img/zabezpecenie-objektov-second.webp);
        height: 550px;
        background-position: 0;
    }
    
    .background-container {
        height: 550px;
    }
    .title-container {
        min-height: 550px;
    }
    
    .services-container {
        font-size: .9em;
    }
    
    .lcc-modal .lcc-modal__actions {
        gap: .5em;
    }
    
    // Camera Page
    
    .camera-container {
        background-position: -200px;
        max-height: 550px;
    }
    
    .components-wraper > div > img {
        max-height: 160px;
    }
    
    .camera-text {
        padding: 1.5em 1em;
        padding-bottom: 0;
    }
    
    .camera-text-pd {
        padding: 0;
    }
    
    .camera-component {
        padding: 1.5em 1em;
    }
    
    .camera-order {
        padding: 1.5em 1em;
    }
    
    // Condition Page
    
    .conditions-wraper {
        padding: 1.5em 1em;
        font-size: .7em;
    }
    
    .conditions-ol {
        padding: 0;
        font-size: 1.4em;
    }
    
    // Smarthome Page
    
    .smarthome-container {
        height: 550px;
        background-position:center;
    }
    
    .smarthome-text {
        padding: 1.5em 1em;
    }
    
    // EZS Page 
    
    .ezs-container {
        height: 550px;
        background-position: -500px;
    }
    
    // Telephones Page
    
    .telephones-container {
        height: 550px;
        background-position:center;
    }
    
    .telephones-wraper img {
        max-width: 100%;
        height: auto;
    }
    
    .img-adjustment {
        max-width: 70%;
        align-self: center;
    }
    
    
        // Product info Box 
    
    .advanced-sub-wraper {
    font-size: .8em;
    }
    
    .advanced-last-sub {
        font-size: .8em;
        text-align: left;
        
        p {
            max-width: 100%;
        }
    }
    
    // kontakt page
    
    .contact-container {
        padding: 1.5em 1em;
    }
    .contact-container > div:nth-child(1) > div:nth-child(2) > h2:nth-child(1) {
        margin-top: 0;
    }
    
    .contact-container > div:nth-child(1) > h1:nth-child(1) {
        margin-bottom: 0;
    }
    
    .info-wraper h1 {
        margin-top: 0;
    }
    
    // Referencie
    
    .references-container {
        padding: 0 1em 1em 1em; 
        
        h1 {
            font-size: 1.7em;
            margin-top: 0 ;
        }
        
        h2 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    
    
    .clients {
        padding: 1em 1em ;
    }
    
    //FAQ
    
    .faq-container {
        padding: 0 1em 1em 1em;
    }
    
    .faq-title-wraper {
        font-size: .7em;
        text-align: center;
    }
    
    .box > div > h2 {
        font-size: .7em;
    }
    
    .box > div > h3 {
        font-size: .6em;
    }
}